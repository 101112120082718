<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px">
      <div>
        <el-row type="flex" justify="space-between">
          <div style="width: 86%" class="mb-4">
            <el-row class="Row-Input">
              <!-- 平台 -->
              <el-col :span="8">
                <el-form-item :label="$t('title.Name')">
                  <el-select v-model="form.name" clearable filterable :placeholder="$t('page.selectPlaceholder')" @visible-change="EmailList(pager)">
                    <el-option
                      v-for="item in formList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('title.EmailAccount')">
                  <el-select v-model="form.emailAddress" clearable filterable :placeholder="$t('page.selectPlaceholder')" @visible-change="EmailList(pager)">
                    <el-option
                      v-for="item in formList"
                      :key="item.id"
                      :label="item.emailAddress"
                      :value="item.emailAddress"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row style="width: 14%">
            <el-col class="row-center">
              <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="500px"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        :label="$t('title.Name')"
        align="center"
        sortable
      />
      <el-table-column
        prop="emailAddress"
        :label="$t('title.EmailAccount')"
        align="center"
        sortable
      />
      <el-table-column
        prop="createdTime"
        :label="$t('title.Blockedtime')"
        align="center"
        sortable
      />
      <el-table-column :label="$t('page.operate')" align="center" prop="operate" width="180">
        <template slot-scope="scope">
          <!-- 启动 -->
          <el-button type="text" @click="handleDelete(scope.row)">{{ $t('title.Remove') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[10, 50, 100, 200, 300]"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import { getShopifyShieldEmail, deleteShopifyShieldEmail } from '@/api/service-management'
export default {
  mixins: [mixin],
  data() {
    return {
      pager: {
        current: 1,
        size: 1000,
        total: 0
      },

      TableLoading: false,
      tableDatas: [],
      form: {
        name: '',
        emailAddress: ''
      },
      formList: []
    }
  },
  computed: {
    queryParames() {
      return Object.assign({}, this.form, this.pager)
    }
  },
  mounted() {
    this._getShopifyShieldEmail()
  },
  methods: {
    // 删除模板
    async handleDelete(row) {
      const { code, msg } = await deleteShopifyShieldEmail(row.id)
      if (code === 0) {
        this.$message.success(msg)
      }
      this.getShopifyShieldEmail(this.queryParames)
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._getShopifyShieldEmail(this.form)
    },
    // 默认查询
    async _getShopifyShieldEmail(params) {
      try {
        this.TableLoading = true
        const { datas } = await getShopifyShieldEmail(this.queryParames)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },
    // 默认查询
    async EmailList() {
      const { datas } = await getShopifyShieldEmail({ size: 10, current: 1 })
      this.formList = datas.records
    },
    handleSizeChange(val) {
      this.pager.size = val
      this.getShopifyShieldEmail(this.queryParames)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this.getShopifyShieldEmail(this.queryParames)
    }
  }
}
</script>

<style scoped lang="scss">
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
